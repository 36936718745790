import React from "react";

function MenuButton({ isMenuOpen, setIsMenuOpen }) {
  return (
    <button className="bg-[#FF0C3D] p-4" onClick={() => {
      setIsMenuOpen(!isMenuOpen);
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="size-8">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
    </button>
  );
}

export default MenuButton;
import React from "react";
import LinkButton from "./components/linkButton";
import MenuButton from "./components/menuButton";
import Menu from "./components/menu";
import Background from "./components/background";

function App() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <div className="App">
      <div className="App-header flex w-full z-10 fixed">
        <div className="w-2/6 flex gap-4">
          <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
          <img src="full-logo.png" alt="logo" onClick={() => {
            window.location.href = "/";
          }} draggable={false} className="h-6 mt-auto mb-auto ml-2 cursor-pointer" />
        </div>
        <div className="w-4/6 justify-end px-2 lg:flex xl:flex md:flex hidden">
          <LinkButton text="Contact Us" link="https://tally.so/r/w5k8WZ" />
          <LinkButton text="Blog" link="https://paragraph.xyz/@ioncore" />
          <LinkButton text="Technology" link="https://paragraph.xyz/@ioncore/applications-of-solid-state-batteries-ushering-in-a-new-era-of-energy-storage" />
        </div>
      </div>
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <div className="z-0">
        <Background />
      </div>
    </div>
  );
}

export default App;

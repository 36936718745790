import React from "react";

function Switcher({ item }) {
  return (
    <div className="flex gap-4">
      <div className="flex">
        <div className="w-1 h-52 bg-[#9F9F9F] opacity-35 rounded-full z-0" />
        <div className="w-1 h-[52px] bg-white rounded-full z-10 absolute" style={{
          transform: `translateY(${item * 52}px)`
        }} />
      </div>
      <div className="flex flex-col justify-between z-0">
        <span className="poppins-medium text-md mt-auto mb-auto">01 MISSION</span>
        <span className="poppins-medium text-md mt-auto mb-auto">02 APPLICATIONS</span>
        <span className="poppins-medium text-md mt-auto mb-auto">03 PHILOSOPHY</span>
        <span className="poppins-medium text-md mt-auto mb-auto">04 FUTURE</span>
      </div>
    </div>
  );
};

export default Switcher;
import React from "react";
import Switcher from "./switcher";
import Header from "./header";

function Background() {
  const [item, setItem] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setItem((item) => (item + 1) % 4);
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <div className="h-full flex">
      {
        item === 0  && (
          <video autoPlay muted className="fixed top-0 left-0 z-0 w-full h-full object-cover" onContextMenu={(e) => {
            e.preventDefault();
          }}>
            <source src="lightning.mp4" type="video/mp4" />
          </video>
        )
      }
      {
        item === 1  && (
          <video autoPlay muted className="fixed top-0 left-0 z-0 w-full h-full object-cover brightness-50" onContextMenu={(e) => {
            e.preventDefault();
          }}>
            <source src="applications.mp4" type="video/mp4" />
          </video>
        )
      }
      {
        item === 2  && (
          <video autoPlay muted className="fixed top-0 left-0 z-0 w-full h-full object-cover brightness-50" onContextMenu={(e) => {
            e.preventDefault();
          }}>
            <source src="nyc.mp4" type="video/mp4" />
          </video>
        )
      }
      {
        item === 3  && (
          <video autoPlay muted className="fixed top-0 left-0 z-0 w-full h-full object-cover brightness-50" onContextMenu={(e) => {
            e.preventDefault();
          }}>
            <source src="forest.mp4" type="video/mp4" />
          </video>
        )
      }
      <div className="z-0 text-white top-[42%] left-[5%] absolute hidden lg:block md:block xl:block">
        <Switcher item={item} />
      </div>
      <div className="z-0 top-[30vh] text-white md:top-[30vw] lg:top-[20vw] left-[12vw] md:left-[30%] lg:left-[30%] absolute">
        {
         item === 0 && (
          <Header
           subheading="A solid mission"
           heading="Powering Tomorrow with Solid Innovation"
           description="Pioneering the future of energy storage with cutting-edge solid-state battery technology. Our solutions promise enhanced safety, efficiency, and sustainability, driving the world toward a cleaner and more connected tomorrow."
           link="https://paragraph.xyz/@ioncore/mission"
           linkText="Learn More"
          />
         ) 
        }
        {
          item === 1 && (
            <Header 
              subheading="an efficient future" 
              heading="Transformative Applications Across Industries" 
              description="Solid-state batteries are revolutionizing a wide range of industries, from electric vehicles to aerospace. By providing superior energy density, safety, and performance, they enable the development of more efficient electric cars, cutting-edge aerospace technologies, and numerous other advanced applications."
              link="https://paragraph.xyz/@ioncore/applications-of-solid-state-batteries-ushering-in-a-new-era-of-energy-storage" 
              linkText="learn more" 
            />
          )
        }
        {
          item === 2 && (
            <Header 
              subheading="Honest Sustainable Energy Solutions" 
              heading="IONCORE'S Foundational Principles" 
              description="Driven by a commitment to innovation, sustainability, and excellence, our philosophy centers on pushing the boundaries of energy storage technology. We believe in creating solutions that not only meet today's needs but also pave the way for a cleaner, more sustainable future." 
              link="https://paragraph.xyz/@ioncore/the-philosophy-and-care-behind-ioncore-innovating-with-purpose-and-precision" 
              linkText="learn more" 
            />
          )
        }
        {
          item === 3 && (
            <Header 
              subheading="Commitment to Future Clean Energy Solutions" 
              heading="Empowering the Future with Clean Energy" 
              description="IONCORE empowers the future with clean energy solutions through advanced solid-state battery technology, ensuring sustainability and efficiency for global industries and communities." 
              link="https://paragraph.xyz/@ioncore/the-future-of-ioncore-advancing-clean-energy-with-automated-manufacturing" 
              linkText="learn more" 
            />
          )
        }
      </div>
    </div>
  );
}

export default Background;
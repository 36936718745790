import React from "react";

function Header({ subheading, heading, description, link, linkText}) {
  return (
    <div className="w-3/4">
      <h2 className="text-white poppins-semibold uppercase text-lg opacity-50">{subheading}</h2>
      <h1 className="text-white poppins-semibold text-6xl mt-4">{heading}</h1>
      <p className="text-white poppins-regular text-lg opacity-75 mt-4">{description}</p>
      {linkText !== null && (
        <button className="flex mt-4 hover:scale-105 transition-all">
          <a href={link} className="flex items-center justify-center w-14 h-14 rounded-full mt-auto mb-auto transition-all border-[3px]" style={{
            background: "rgba(255, 255, 255, 0.4)"
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="size-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v14M5 12h14" />
            </svg>
          </a>
          <a
            href={link}
            className="text-lg ml-4 text-white opacity-50 poppins-medium uppercase mt-auto mb-auto"
          >
            {linkText}
          </a>
        </button>
      )}
    </div>
  );
}

export default Header;
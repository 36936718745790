import React from "react";

function LinkButton({ text, link }) {
  return (
    <a href={link} className="p-4 text-lg text-white poppins-regular mt-auto mb-auto hover:scale-105">
      {text}
    </a>
  );
}

export default LinkButton;
import React from "react";
import { motion } from "framer-motion";

function Menu({ isMenuOpen, setIsMenuOpen }) {
  return (
    <motion.div
      className={`w-full h-full bg-[#FF0C3D] fixed top-0 left-0 z-50 flex items-center justify-center ${isMenuOpen ? "flex" : "hidden"}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: isMenuOpen ? 1 : 0 }}
    >
      <div className="w-1/2 flex flex-col px-20">
        <a href="/" className="p-4 text-5xl text-white poppins-medium mt-auto mb-auto hover:scale-105 transition-all">
          Home
        </a>
        <a href="https://paragraph.xyz/@ioncore/applications-of-solid-state-batteries-ushering-in-a-new-era-of-energy-storage" className="p-4 text-5xl text-white poppins-medium mt-auto mb-auto hover:scale-105 transition-all">
          Technology
        </a>
        <a href="https://paragraph.xyz/@ioncore" className="p-4 text-5xl text-white poppins-medium mt-auto mb-auto hover:scale-105 transition-all">
          Blog
        </a>
        <a href="https://tally.so/r/w5k8WZ" className="p-4 text-5xl text-white poppins-medium mt-auto mb-auto hover:scale-105 transition-all">
          Contact
        </a>
      </div>
      <div className="h-full flex w-1/2">
        <button className="bg-[#FF0C3D] p-4 mb-auto ml-auto" onClick={() => setIsMenuOpen(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="size-12">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </motion.div>
  );
}

export default Menu;